@import 'src/styles/variables/variables';

@include useTheme();

.Field {
  width: 100%;
  
  label {
    margin: 0 0 5px;
    display: block;
    font-size: 14px;
    line-height: 15.4px;
  }

  input {
    width: 100%;
    border: 1px solid $shade1;
    border-radius: 3px;
    line-height: 35px;
    padding: 4px 8px;
  }

  .Error {
    font-size: 12px;
    line-height: 13.2px;
    text-align: left;
    margin-top: 6px;
    justify-content: left;
  }
}