@import 'src/styles/variables/variables';
.AspectRatioBox {
  height: 0;
  overflow: hidden;
  padding-top: (1px / 1px) * 100%; // square
  position: relative;
}

.AspectRatioBoxInside {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
