@import 'src/styles/variables/variables';

@include useTheme();

.Or {
  width: 100%;
  display: flex;
  align-items: center;
  color: $gray4;

  .OrText {
    margin: 0 10px;
  }

  .Line {
    flex: 1;
    border-bottom: 1px solid $gray4;
  }
}