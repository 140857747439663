@import 'src/styles/variables/variables';

@include useTheme();

.ChangeMethodSignUp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Forgot {
  margin-bottom: 8px;
}

.Forgot, .SignUp {
  @include primaryFont(400);
  font-size: 14px;
  color: $gray6;
}

.OrLayout {
  margin-bottom: 11px;
  padding: 0 90px;
}
