@import 'src/styles/variables/variables';

@include useTheme();

.Form {
  padding: 68px 0 60px;
  border-radius: 27px;
  background: $core2;
}

.Subtitle {
  margin-bottom: 12px;
}

.Title, .Subtitle {
  text-align: center;
}

.GlobalError {
  color: $core7;
  font-size: 12px;
  line-height: 13.2px;
  display: flex;
  justify-content: center;
  visibility: hidden;
  margin-bottom: 4px;

  &.Visible {
    visibility: visible;
  }

  svg {
    margin-top: -3px;
    margin-right: 3px;

    path {
      stroke: $core7;
    }
  }
}
