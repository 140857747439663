@import 'src/styles/variables/variables';

@include useTheme();

.Root {
  display: grid;
  grid-template: 'container';
  place-items: center;
  place-content: center;

  @include min-width(tablet) {
    height: 95vh;
    background: whitesmoke no-repeat 50% 85%;
    background-size: cover;
  }
}

.Root > * {
  grid-area: container;
  max-width: 478px;

  @include min-width(tablet) {
    width: 478px;
  }
}

.Overlay {
  z-index: 9998;
  width: 100%;
  height: 100%;
  background: $core1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  border-radius: 27px;
}

.FieldGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 20px;
  padding: 0 90px;
  margin: 0 0 12px;
}

.CTAButton {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 90px;
  margin-bottom: 28px;

  button {
    width: 100%;
  }
}


.MagicLinkDescription {
  background-color: $tint1;
  color: #5c5c5c;
  margin: 10px 57px 20px !important;
  padding: 8px 16px;

  a {
    color: #5c5c5c;
    text-decoration: underline;
  }
}

.LoginWithMagicLink {
  @include primaryFont(400);
  font-size: 14px;
  color: $gray6;
  text-align: center;

  a {
    text-decoration: underline;
  }
}

.ChangeMethodSignUpLayout {
  margin-top: 22px;
}

.TextInputLayout {
  margin-bottom: 20px;
}

.EmailSent {
  margin: 0 57px;
}

.LoginConfirmLayout {
  padding: 68px 90px 60px;
}

.Forgot {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  a {
    @include primaryFont(400);
    font-size: 14px;
    color: $gray6;
  }
}