@import '../../../styles/variables/variables';

.m-loader {
  position: relative;
  height: 100%;

  &__container-outer {
    height: 100%;
  }

  &.-fullscreen {
    position: static;
    height: 100%;
    width: 100%;

    .m-loader__container-outer {
        height: 100vh;
      }
  }

  & img {
    width: 200px !important;
  }
}


